import React, { useState } from "react";
import LivornLogo from "../assets/LivornLogo";
import { useNavigate } from "react-router-dom";
import { isTabDevice } from "../utils/Constants";
import Colors, { hexToRGBA } from "../utils/Colors";
import { goToPlayStore } from "../functions/goToPlayStore";
import { useIsMobileDevice } from "../utils/useIsMobileDevice";
import AnimatedImage from "./AnimatedImage";

const HeaderButton = ({ name, onClick }) => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  return (
    <div
      onClick={onClick}
      style={{ backgroundColor, ...headerButtonStyle }}
      onMouseLeave={() => setBackgroundColor("transparent")}
      onMouseEnter={() => setBackgroundColor(hexToRGBA(Colors.livornPrimary, 0.2))}
    >
      <h4>{name}</h4>
    </div>
  );
};

const DownloadButton = () => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  return (
    <div
      onClick={goToPlayStore}
      style={{ backgroundColor, ...downloadButtonStyle }}
      onMouseEnter={() => setBackgroundColor(hexToRGBA(Colors.livornPrimary, 0.75))}
      onMouseLeave={() => setBackgroundColor(hexToRGBA(Colors.livornSecondary, 0.75))}
    >
      <h4>Download App</h4>
    </div>
  );
};

// Header Component
const Header = () => {
  const navigate = useNavigate();
  const isMobileDevice = useIsMobileDevice();

  return (
    <div style={{ height: isMobileDevice ? "60px" : "80px", ...headerStyle }}>
      {!isMobileDevice && (
        <>
          {/* Logo and Title */}
          <div style={logoStyle} onClick={() => navigate("/")}>
            <AnimatedImage
              src={require("../assets/HoshIcon.png")}
              style={{
                width: isMobileDevice ? 20 : 50,
                height: isMobileDevice ? 20 : 50,
                borderRadius: 10,
                marginLeft: isMobileDevice ? 0 : 130,
              }}
            />
            {/* <LivornLogo scale={0.055} /> */}
            <h3 style={{ marginLeft: 12, marginTop: 12 }}>Hosh AI</h3>
          </div>

          {/* Menus */}
          {!isTabDevice && (
            <>
              <HeaderButton
                name="Reviews"
                onClick={() => navigate("/", { state: { scrollTo: "reviews" } })}
              />
              <HeaderButton
                name="Benefits"
                onClick={() => navigate("/", { state: { scrollTo: "benefits" } })}
              />
              <HeaderButton name="Contact" onClick={() => navigate("/contact-us")} />
              <HeaderButton name="Team" onClick={() => navigate("/team")} />
            </>
          )}

          {/* Download Button */}
          <DownloadButton />
        </>
      )}
      {isMobileDevice && (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <LivornLogo scale={0.035} />
        </div>
      )}
    </div>
  );
};

export default Header;

const headerStyle = {
  top: 0,
  zIndex: 1000,
  width: "100%",
  color: "white",
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0)",
  backdropFilter: "saturate(180%) blur(30px)",
  WebkitBackdropFilter: "saturate(180%) blur(30px)",

  // Wix
  // background-color: "rgba(0,0,0,0)",
  // margin: "0px",
  // backdrop-filter: "blur(60px)",
  // transform: "scale(1)",
  // -webkit-backdrop-filter: "saturate(180%) blur(60px)",
  // backdrop-filter: "saturate(180%) blur(60px)",
};

const logoStyle = {
  left: "4vw",
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  position: "absolute",
};

const headerButtonStyle = {
  border: "none",
  cursor: "pointer",
  margin: "0px 10px",
  borderRadius: "50px",
  padding: "2px 20px 2px 20px",
  transition: "background-color 0.3s ease",
};

const downloadButtonStyle = {
  right: "4vw",
  borderWidth: 2,
  cursor: "pointer",
  padding: "0px 20px",
  borderStyle: "solid",
  borderRadius: "50px",
  position: "absolute",
  borderColor: Colors.livornPrimary,
};
