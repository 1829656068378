import React, { useState, useEffect } from "react";
import useEnterPressed from "../hooks/useEnterPressed";
import PrimaryButton from "../components/PrimaryButton";
import styles from "../styles/PremiumAdminPage.module.css";
import getFutureTimestamp from "./../functions/getFutureTimestamp";
import { equalTo, get, orderByChild, query, ref } from "firebase/database";
import { db, getPushKeyFromFirebase, updateOnFirebase } from "../utils/firebase";

// First Step
const PasswordStep = ({ nextStep }) => {
  const [input, setInput] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const loginHandler = () => {
    if (input === "livorn@cubes") {
      nextStep();
    } else {
      setShowAlert(true);
    }
  };

  // Handling Enter
  useEnterPressed(loginHandler);

  return (
    <div className={styles.onboardingContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>Livorn Admin Portal</h2>

        {/* Login */}
        <input
          type="text"
          value={input}
          className={styles.topicInput}
          placeholder="Enter Admin Portal Code"
          onChange={(e) => setInput(e.target.value)}
        />

        {/* Alert */}
        {showAlert && <p style={{ color: "#FF1A1A" }}>Invalid Admin Password</p>}

        {/* Login Button */}
        <PrimaryButton title="Login" width="30%" onClick={loginHandler} />
      </div>
    </div>
  );
};

const UserDetails = ({ nextStep }) => {
  const [app, setApp] = useState("");
  const [email, setEmail] = useState("");
  const [premiumTime, setPremiumTime] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  // Used to handle premium values
  const premiumDaysMapping = {
    "3 Days": 3,
    "7 Days": 7,
    "1 Month": 30,
    "3 Months": 90,
    "6 Months": 180,
    "1 Year": 365,
    Forever: true,
  };
  const premiumDays = premiumDaysMapping[premiumTime];
  const premiumIdentifier =
    typeof premiumDays === "number" ? getFutureTimestamp(premiumDays) : true;

  // Resetting Alert Message
  useEffect(() => {
    setAlertMessage("");
  }, [app, email, premiumTime]);

  // Handle Submission
  const handleSubmit = async () => {
    // All Inputs
    if (!email || !app || !premiumTime) {
      setAlertMessage("Please provide all inputs");
      return;
    }

    let userKey;
    const today = new Date();
    try {
      // Geting userKey based on Email
      const allUsersRef = ref(db, "allUsers");
      const userKeyQuery = query(allUsersRef, orderByChild("email"), equalTo(email));

      // Snapshot
      const snapshot = await get(userKeyQuery);
      if (snapshot.exists()) {
        userKey = Object.values(snapshot.val())[0]?.userKey;
      } else {
        throw new Error(`Can't find userKey based on email: ${email}`);
      }

      if (!userKey) {
        throw new Error("User Key is undefined / null");
      }

      // New key for storing logs on firebase
      const logKey = getPushKeyFromFirebase({ path: `premiumLogs/${userKey}` });

      // Updates object
      const updates = {};

      // Livorn App Premium
      if (app === "Livorn App") {
        updates[`allUsers/${userKey}/isPremiumUser`] = premiumIdentifier;
      }

      // Gyaan App Premium
      if (app === "Gyaan App") {
        updates[`allUsers/${userKey}/isGyaanPremiumUser`] = premiumIdentifier;
      }

      // Logs
      updates[`premiumLogs/${userKey}/${logKey}`] = {
        app,
        email,
        premiumTime,
        dateString: today.toString(),
        timeStamp: today.getTime(),
      };

      // Updating on firebase
      updateOnFirebase({ updates });

      // Updating Step
      nextStep();
    } catch (error) {
      setAlertMessage(`Some error in accessing premium \n${error}`);
    }
  };

  // Handling Enter
  useEnterPressed(handleSubmit);

  return (
    <div className={styles.onboardingContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.heading}>Enter Student's Details</h2>

        {/* Email */}
        <input
          type="email"
          value={email}
          placeholder="Email"
          className={styles.topicInput}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Select App */}
        <select
          value={app}
          className={styles.selectDropdown}
          onChange={(e) => setApp(e.target.value)}
        >
          <option value="" disabled>
            Select App
          </option>
          {["Livorn App", "Gyaan App"].map((src) => (
            <option key={src} value={src}>
              {src}
            </option>
          ))}
        </select>

        {/* Select Premium Time */}
        <select
          value={premiumTime}
          className={styles.selectDropdown}
          onChange={(e) => setPremiumTime(e.target.value)}
        >
          <option value="" disabled>
            Select Premium Time
          </option>
          {Object.keys(premiumDaysMapping).map((src) => (
            <option key={src} value={src}>
              {src}
            </option>
          ))}
        </select>

        {/* Alert */}
        {alertMessage && <p style={{ color: "#FF1A1A" }}>{alertMessage}</p>}

        {/* Submit Button */}
        <PrimaryButton marginTop={30} onClick={handleSubmit} title="Activate Premium" />
      </div>
    </div>
  );
};

// Premium Admin Page
const PremiumAdminPage = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  return (
    <>
      {step === 1 && <PasswordStep nextStep={nextStep} />}
      {step === 2 && <UserDetails nextStep={nextStep} />}
      {step === 3 && (
        <div className={styles.codeScreen}>
          <p>Premium Created for user</p>
          <PrimaryButton width="50%" marginTop={20} onClick={() => setStep(2)} title="Do Again" />
        </div>
      )}
    </>
  );
};

export default PremiumAdminPage;
