import React from "react";
import { contactEmail } from "./../utils/Constants";
import DisplaySection from "../components/DisplaySection";

export const ContactSection = () => {
  return (
    <DisplaySection
      heading="Contact"
      buttonTitle={contactEmail}
      imageSource={require("../assets/Contact.png")}
      imageStyles={{ height: "20vw", width: "auto", marginLeft: -20 }}
      onButtonClick={() => window.open(`mailto:${contactEmail}`, "_blank")}
      text="You can contact us anytime and we will reply within 1-2 days."
      description=" You can contact for any reasons like Investing, Job applications, feedback of Cubes products & services and all aspects of Cubes and Hosh AI."
    />
  );
};

export const HelpSection = () => {
  return (
    <DisplaySection
      heading="Help"
      reverse={true}
      marginRight={100}
      buttonTitle={contactEmail}
      imageSource={require("../assets/Help.png")}
      imageStyles={{ height: "25vw", width: "auto", marginRight: 100 }}
      onButtonClick={() => window.open(`mailto:${contactEmail}`, "_blank")}
      text="You can ask for any kind of help here about Cubes."
      description=" Our support team will give their best efforts to make your experience better and we will be happy to assist you with any kind of queries in any aspect of Cubes and Hosh AI."
    />
  );
};

const ContactPage = () => {
  return (
    <div style={{ paddingTop: 60 }}>
      <ContactSection />
      <HelpSection />
    </div>
  );
};

export default ContactPage;
